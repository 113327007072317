<template>
  <div class="page">
    <div class="title">员工花名册</div>

    <!-- filter -->
    <div class="filter_box">
      <div class="filter_search">
        <el-input
          class="filter_search_input"
          placeholder="请输入关键词"
          v-model="search.keyword"
          clearable
        >
        </el-input>
        <div class="custom_button no_select" @click="toSearch(false)">搜索</div>

        <div
          class="custom_button plain no_select"
          @click="onAddEmployeeQrcodeTap"
        >
          添加员工
        </div>

        <div class="custom_button plain no_select" @click="downloadTamplate">
          下载导入模板
        </div>
        <div class="custom_button no_select" @click="chooseExcel">
          导入花名册
        </div>
        <div class="custom_button no_select" @click="exportRoster">
          导出花名册
        </div>
        <div class="custom_button no_select" @click="resetFormSearch">重置</div>
        <input
          ref="fileInput"
          type="file"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
          @change="importRoster"
          @click="
            (event) => {
              event.target.value = null;
            }
          "
          style="width: 0; height: 0; position: absolute"
        />
      </div>
    </div>

    <el-table
      ref="table"
      :data="tableData"
      :header-cell-style="tableHeaderStyle"
      :row-class-name="tableRowClassName"
      :cell-style="tableCellStyle"
      style="width: 100%"
      @cell-click="onCellClick"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="department_data.department_name" label="所在单位">
      </el-table-column>
      <el-table-column
        prop="department_data.duty_name"
        label="所在项目"
        min-width="150"
      ></el-table-column>
      <el-table-column prop="department_data.station_name" label="岗位名称">
      </el-table-column>

      <el-table-column prop="real_name" label="姓名"> </el-table-column>

      <el-table-column prop="address" label="户口所在省市" width="150px">
      </el-table-column>
      <el-table-column prop="mobile" label="本人联系方式" min-width="120">
      </el-table-column>
      <el-table-column
        prop="earliest_entry_time"
        label="最早入职时间"
        min-width="150"
      ></el-table-column>
      <el-table-column
        prop="contract_first_begin_time"
        label="初始合同起期"
        min-width="150"
      >
      </el-table-column>

      <el-table-column
        prop="contract_begin_time"
        label="当期合同起期"
        min-width="150"
      >
      </el-table-column>
      <el-table-column prop="salary_base" label="月度薪酬标准" min-width="150">
      </el-table-column>
      <el-table-column prop="payroll_company" label="发薪公司">
      </el-table-column>

      <el-table-column label="操作" fixed="right" min-width="120">
        <template v-slot:default="scope">
          <el-dropdown size="mini" split-button type="primary">
            操作
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                @click.native="
                  $router.push({
                    name: 'EmployeeDetail',
                    params: { id: scope.row.id, isSave: true },
                  })
                "
                >修改
              </el-dropdown-item>
              <el-dropdown-item
                @click.native="setAdmin(scope.row.id, scope.row.mobile)"
                >分配用户权限</el-dropdown-item
              >
              <!-- <el-dropdown-item
                                @click.native="syncUserPunchMachine(scope.row)"
                                >同步到打卡机</el-dropdown-item
                            > -->
              <el-dropdown-item @click.native="postTransfer([scope.row])"
                >调岗</el-dropdown-item
              >

              <el-dropdown-item
                v-if="scope.row.quit_type != 3"
                @click.native="showTransfer([scope.row])"
                >借岗</el-dropdown-item
              >

              <el-dropdown-item @click.native="changeSalary([scope.row])"
                >调薪</el-dropdown-item
              >

              <el-dropdown-item
                v-if="scope.row.quit_type != 3"
                @click.native="leavePost(scope.row.id)"
                >离职</el-dropdown-item
              >
              <el-dropdown-item
                v-if="scope.row.quit_type == 3"
                @click.native="leavePost2(scope.row.id)"
                >自离</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <!-- <el-scrollbar style="height:280px;width: 540px"/> -->

    <el-pagination
      layout="prev, pager, next"
      :total="page.total"
      :page-size="page.size"
      :current-page.sync="page.page"
      @current-change="loadData()"
    >
    </el-pagination>

    <!-- <div class="bottom_betch_operation">
      <el-select class="betch_select" v-model="batch">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <div class="custom_button no_select" @click="toBatchProcessing">
        批量处理
      </div>
    </div> -->

    <el-dialog title="添加员工" :visible.sync="dialogVisible" width="30%">
      <el-form :model="form" ref="form" :rules="rules">
        <el-form-item label="员工姓名" prop="real_name">
          <el-input v-model="form.real_name"></el-input>
        </el-form-item>
        <el-form-item label="员工手机号" prop="mobile">
          <el-input v-model="form.mobile"></el-input>
        </el-form-item>
        <el-form-item label="员工子公司/部门：" prop="department">
          <el-cascader
            v-model="form.department"
            :options="department_options"
            collapse-tags
          ></el-cascader>
        </el-form-item>
        <el-form-item label="员工岗位：" prop="station_id">
          <el-select v-model="form.station_id" placeholder="请选择">
            <el-option
              v-for="item in station_options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <div class="custom_button no_select" @click="onAddEmployee">确 定</div>
      </span>
    </el-dialog>
    <!-- 借岗 -->
    <el-dialog
      title="员工临时借岗申请"
      :visible.sync="dialogVisible9"
      width="30%"
    >
      <el-form :model="form9" ref="form9" :rules="rules9">
        <el-form-item label="员工姓名" prop="real_name"
          >{{ form9.real_name }}
        </el-form-item>
        <el-form-item label="员工子公司/部门：" prop="department">
          <el-cascader
            v-model="form9.department"
            :options="department_options"
            collapse-tags
          ></el-cascader>
        </el-form-item>
        <el-form-item label="员工岗位：" prop="station_id">
          <el-select v-model="form9.station_id" placeholder="请选择">
            <el-option
              v-for="item in station_options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="借岗月薪" prop="borrow_salary_base">
          {{ form9.borrow_salary_base }}
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <div class="custom_button no_select" @click="borrowPostTransfers">
          确 定
        </div>
      </span>
    </el-dialog>

    <el-dialog title="添加员工" :visible.sync="dialogVisible2" width="30%">
      <!-- <el-image :src="addQrcode"></el-image> -->
      <div>二维码{{ company_id }}</div>
    </el-dialog>

    <el-dialog title="员工调薪" :visible.sync="dialogVisible3" width="30%">
      <el-form :model="form3" ref="form3" :rules="rules3">
        <el-form-item label="员工姓名" prop="real_name">
          <el-input v-model="form3.real_name" disabled></el-input>
        </el-form-item>
        <el-form-item label="基本工资" prop="salary_base">
          <el-input v-model="form3.salary_base"></el-input>
        </el-form-item>
        <el-form-item label="正常工作时间工资" prop="normal_work_salary">
          <el-input v-model="form3.normal_work_salary"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer" style="display: flex">
        <div class="custom_button no_select" @click="toSign">发起签署</div>
        <div
          class="custom_button no_select"
          style="margin-left: 10px"
          @click="onChangeSalary"
        >
          确 定
        </div>
      </span>
    </el-dialog>

    <el-dialog
      :title="form4.real_name + '员工调岗'"
      :visible.sync="dialogVisible4"
      width="30%"
      :close-on-click-modal="false"
    >
      <el-form :model="form4" ref="form4" :rules="rules4">
        <el-form-item label="员工姓名" prop="real_name">
          <el-input v-model="form4.real_name" disabled></el-input>
        </el-form-item>
        <el-form-item label="员工子公司/部门：" prop="department">
          <el-cascader
            v-model="form4.department"
            :options="department_options"
            collapse-tags
            :props="{ checkStrictly: true }"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="员工岗位：" prop="station_id">
          <el-select v-model="form4.station_id" placeholder="请选择">
            <el-option
              v-for="item in station_options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="基本薪水" prop="new_salary_base">
          <el-input v-model="form4.new_salary_base" type="number"></el-input>
        </el-form-item>
        <el-form-item label="出勤标准" prop="new_attendance_standard">
          <el-input v-model="form4.new_attendance_standard"></el-input>
        </el-form-item>
        <el-form-item label="正常工作时间工资" prop="new_normal_work_salary">
          <el-input
            v-model="form4.new_normal_work_salary"
            type="number"
          ></el-input>
        </el-form-item>
        <el-form-item label="生效时间" prop="certifity_expired">
          <!-- <el-date-picker
            v-model="form4.certifity_expired"
            type="date"
            placeholder="请选择生效时间"
            value-format="yyyy-MM"
          >
          </el-date-picker> -->
          <el-date-picker
            v-model="form4.certifity_expired"
            type="month"
            value-format="yyyy-MM"
            placeholder="请选择生效时间"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer" style="display: flex">
        <!-- <div class="custom_button no_select" @click="toSign">发起签署</div> -->
        <div
          class="custom_button no_select"
          style="margin: 10px auto"
          @click="onPostTransfer"
        >
          确定提交
        </div>
      </span>
    </el-dialog>

    <el-dialog title="员工离职" :visible.sync="dialogVisible5" width="30%">
      <template v-if="leave">
        <el-form :model="form5" ref="form5" :rules="rules5">
          <el-form-item prop="quit_type">
            <el-radio-group v-model="form5.quit_type" @change="changeQuitType">
              <el-radio :label="1">辞职</el-radio>
              <el-radio :label="2">辞退</el-radio>
              <!-- <el-radio :label="3">自离</el-radio>
              <el-radio :label="4">协商解除</el-radio> -->
            </el-radio-group>
          </el-form-item>
          <el-form-item label="离职时间：" prop="quit_time">
            <el-date-picker
              v-model="form5.quit_time"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
              align="center"
              key="form5"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="离职原因：" prop="reason">
            <el-radio-group v-model="form5.reason">
              <el-radio label="个人原因">个人原因</el-radio>
              <el-radio label="其他">其他</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            v-if="form5.quit_type == 2 || form5.quit_type == 4"
            label="经济补偿金："
            prop="economic_compensation"
          >
            <el-input
              type="number"
              v-model="form5.economic_compensation"
              style="width: 100px"
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer" style="display: flex">
          <!-- <div class="custom_button no_select" @click="toSign">发起签署</div> -->
          <div
            class="custom_button no_select"
            style="margin-left: 10px"
            @click="onLeavePost"
          >
            确 定
          </div>
        </span>
      </template>
      <template v-else>
        <el-form :model="form6" ref="form6" :rules="rules6">
          <el-form-item prop="quit_type">
            <el-radio-group v-model="form6.quit_type" @change="changeQuitType">
              <el-radio :label="1">辞职</el-radio>
              <el-radio :label="2">辞退</el-radio>
              <el-radio :label="3">自离</el-radio>
              <el-radio :label="4">协商解除</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="未上班日期：" prop="nowork_time">
            <el-date-picker
              v-model="form6.nowork_time"
              type="daterange"
              align="center"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              @change="getNoworkTime"
              key="form6"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="旷工天数：" prop="quit_days">
            <el-input
              type="number"
              v-model="form6.absenteeism"
              style="width: 100px"
            ></el-input>
            天
          </el-form-item>
          <el-form-item label="二次催告时间：" prop="quit_remind_time">
            <el-date-picker
              v-model="form6.quit_remind_time"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
              align="center"
              key="form9"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer" style="display: flex">
          <div class="custom_button no_select" @click="toReminder">
            发起催告通知
          </div>
          <div
            class="custom_button plain no_select"
            style="margin-left: 10px"
            @click="dialogVisible5 = false"
          >
            取消
          </div>
        </span>
      </template>
    </el-dialog>

    <el-dialog title="员工离职" :visible.sync="dialogVisible6" width="30%">
      <el-form>
        <el-form-item>
          <el-radio-group v-model="form7.quit_type" disabled>
            <el-radio :label="1">辞职</el-radio>
            <el-radio :label="2">辞退</el-radio>
            <!-- <el-radio :label="3">自离</el-radio>
            <el-radio :label="4">协商解除</el-radio> -->
          </el-radio-group>
        </el-form-item>
        <el-form-item label="未上班日期：" prop="nowork_time">
          <el-date-picker
            v-model="form7.nowork_time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            disabled
            align="center"
            key="form7"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="旷工天数：" prop="absenteeism">
          <el-input
            v-model="form7.absenteeism"
            disabled
            style="width: 100px"
          ></el-input>
          天
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer" style="display: flex">
        <div class="custom_button no_select" @click="toRelieveContract">
          解除劳动合同
        </div>
        <div
          class="custom_button no_select"
          style="margin-left: 10px"
          @click="toRelieveReminder"
        >
          解除催告
        </div>
        <div
          class="custom_button plain no_select"
          style="margin-left: 10px"
          @click="dialogVisible6 = false"
        >
          取消
        </div>
      </span>
    </el-dialog>

    <el-dialog title="员工档案" :visible.sync="dialogVisible7">
      <el-form>
        <el-row style="display: flex; justify-content: space-between">
          <el-col :span="11">
            <el-form-item label="姓名">
              <el-input readonly v-model="employeeDetail.real_name"></el-input>
            </el-form-item>
            <el-form-item label="性别">
              <el-input readonly v-model="employeeDetail.sex"></el-input>
            </el-form-item>
            <el-form-item label="生日">
              <el-input readonly v-model="employeeDetail.birthday"></el-input>
            </el-form-item>
            <el-form-item :label="employeeDetail.cert_type || '居民身份证'">
              <el-input
                readonly
                v-model="employeeDetail.cert_number"
              ></el-input>
            </el-form-item>
            <el-form-item
              v-if="employeeDetail.certifity_expired"
              label="身份证有效期"
            >
              <el-input
                readonly
                v-model="employeeDetail.certifity_expired"
              ></el-input>
            </el-form-item>
            <el-form-item label="民族">
              <el-input readonly v-model="employeeDetail.nation"></el-input>
            </el-form-item>
            <el-form-item label="面貌">
              <el-input readonly v-model="employeeDetail.face"></el-input>
            </el-form-item>
            <!-- <el-form-item label="教育经历">
                            <el-input
                                readonly
                                type="textarea"
                                v-model="employeeDetail.edu_exp"
                            ></el-input>
                        </el-form-item> -->
            <el-form-item label="是否已婚">
              <el-checkbox
                disabled
                v-model="employeeDetail.is_marry"
              ></el-checkbox>
            </el-form-item>
            <!-- <el-form-item label="工作经历">
                            <el-input
                                readonly
                                type="textarea"
                                v-model="employeeDetail.job_exp"
                            ></el-input>
                        </el-form-item> -->
            <!-- <el-form-item label="是否已育">
                            <el-checkbox
                                disabled
                                v-model="employeeDetail.is_birth"
                            ></el-checkbox>
                        </el-form-item> -->
            <!-- <el-form-item label="是否首次缴纳社保">
                            <el-checkbox
                                disabled
                                v-model="
                                    employeeDetail.is_first_social_security
                                "
                            ></el-checkbox>
                        </el-form-item> -->
            <!-- <el-form-item label="是否首次缴纳公积金">
                            <el-checkbox
                                disabled
                                v-model="
                                    employeeDetail.is_first_accumulation_fund
                                "
                            ></el-checkbox>
                        </el-form-item> -->
            <el-form-item
              v-if="employeeDetail.accumulation_fund"
              label="公积金"
            >
              <el-input
                readonly
                v-model="employeeDetail.accumulation_fund"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="户籍">
              <el-input
                readonly
                v-model="employeeDetail.household_registration"
              ></el-input>
            </el-form-item>
            <el-form-item label="详细地址">
              <el-input readonly v-model="employeeDetail.address"></el-input>
            </el-form-item>
            <el-form-item label="户口性质">
              <el-input
                readonly
                v-model="employeeDetail.residence_type"
              ></el-input>
            </el-form-item>
            <el-form-item label="现居地址">
              <el-input
                readonly
                v-model="employeeDetail.current_residence"
              ></el-input>
            </el-form-item>
            <el-form-item label="手机号">
              <el-input readonly v-model="employeeDetail.mobile"></el-input>
            </el-form-item>
            <!-- <el-form-item label="邮箱">
                            <el-input
                                readonly
                                v-model="employeeDetail.email"
                            ></el-input>
                        </el-form-item> -->
            <!-- <el-form-item label="QQ">
                            <el-input
                                readonly
                                v-model="employeeDetail.qq"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="微信">
                            <el-input
                                readonly
                                v-model="employeeDetail.wechat"
                            ></el-input>
                        </el-form-item> -->
            <!-- <el-form-item label="银行名称">
                            <el-input
                                readonly
                                v-model="employeeDetail.bank_name"
                            ></el-input>
                        </el-form-item> -->
            <el-form-item label="银行卡号">
              <el-input readonly v-model="employeeDetail.bank_no"></el-input>
            </el-form-item>
            <el-form-item label="开户银行">
              <el-input
                readonly
                v-model="employeeDetail.bank_deposit"
              ></el-input>
            </el-form-item>
            <el-form-item label="是否退伍军人">
              <el-checkbox
                disabled
                v-model="employeeDetail.is_veteran"
              ></el-checkbox>
            </el-form-item>
            <!-- <el-form-item label="是否有重大疾病">
                            <el-checkbox
                                disabled
                                v-model="employeeDetail.is_major_diseases"
                            ></el-checkbox>
                        </el-form-item>
                        <el-form-item
                            label="重大疾病说明"
                            v-if="employeeDetail.major_diseases"
                        >
                            <el-input
                                readonly
                                v-model="employeeDetail.major_diseases"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="是否有残疾">
                            <el-checkbox
                                disabled
                                v-model="employeeDetail.is_disability"
                            ></el-checkbox>
                        </el-form-item>
                        <el-form-item
                            label="残疾说明"
                            v-if="employeeDetail.disability"
                        >
                            <el-input
                                readonly
                                v-model="employeeDetail.disability"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="是否有职业病">
                            <el-checkbox
                                disabled
                                v-model="employeeDetail.is_occupation_diseases"
                            ></el-checkbox>
                        </el-form-item>
                        <el-form-item
                            label="职业病说明"
                            v-if="employeeDetail.occupation_diseases"
                        >
                            <el-input
                                readonly
                                v-model="employeeDetail.occupation_diseases"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="是否从事过重体力劳动或有害工种">
                            <el-checkbox
                                disabled
                                v-model="employeeDetail.is_harmful"
                            ></el-checkbox>
                        </el-form-item>
                        <el-form-item
                            label="重体力劳动或有害工种说明"
                            v-if="employeeDetail.harmful"
                        >
                            <el-input
                                readonly
                                v-model="employeeDetail.harmful"
                            ></el-input>
                        </el-form-item> -->
          </el-col>
        </el-row>
        <el-divider></el-divider>
        <el-form-item>
          <el-row style="display: flex; justify-content: space-between">
            <el-col :span="11">
              <el-form-item label="学历">
                <el-input readonly v-model="employeeDetail.edu"></el-input>
              </el-form-item>
              <el-form-item
                label="毕业学校"
                v-if="employeeDetail.graduation_college"
              >
                <el-input
                  readonly
                  v-model="employeeDetail.graduation_college"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="学历证书编号"
                v-if="employeeDetail.edu_prove"
              >
                <el-input
                  readonly
                  v-model="employeeDetail.edu_prove"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item
                label="毕业时间"
                v-if="employeeDetail.graduation_time"
              >
                <el-input
                  readonly
                  v-model="employeeDetail.graduation_time"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="毕业专业"
                v-if="employeeDetail.graduation_major"
              >
                <el-input
                  readonly
                  v-model="employeeDetail.graduation_major"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form-item>
        <el-divider></el-divider>
        <el-form-item>
          <el-row style="display: flex; justify-content: space-between">
            <el-col :span="11">
              <el-form-item label="发薪公司">
                <el-input
                  readonly
                  v-model="employeeDetail.payroll_company"
                ></el-input>
              </el-form-item>
              <el-form-item label="加点替岗1工资标准">
                <el-input
                  readonly
                  v-model="employeeDetail.add_replace_wages_standard_first"
                ></el-input>
              </el-form-item>
              <el-form-item label="加点替岗类别">
                <el-input
                  readonly
                  v-model="employeeDetail.add_replace_wages_standard_type"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="公司名称">
                <el-input
                  readonly
                  v-model="employeeDetail.company_name"
                ></el-input>
              </el-form-item>
              <el-form-item label="加点替岗2工资标准">
                <el-input
                  readonly
                  v-model="employeeDetail.add_replace_wages_standard_second"
                ></el-input>
              </el-form-item>
              <el-form-item label="加点替岗3工资标准">
                <el-input
                  readonly
                  v-model="employeeDetail.add_replace_wages_standard_third"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form-item>
        <el-divider></el-divider>
        <el-form-item>
          <el-row style="display: flex; justify-content: space-between">
            <el-col :span="11">
              <el-form-item
                label="参加工作时间"
                v-if="employeeDetail.work_time"
              >
                <el-input
                  readonly
                  v-model="employeeDetail.work_time"
                ></el-input>
              </el-form-item>
              <el-form-item label="最早入职时间">
                <el-input
                  readonly
                  v-model="employeeDetail.earliest_entry_time"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="入职集团公司时间"
                v-if="employeeDetail.join_group_company_time"
              >
                <el-input
                  readonly
                  v-model="employeeDetail.join_group_company_time"
                ></el-input>
              </el-form-item>
              <el-form-item label="入职劳务公司时间">
                <el-input
                  readonly
                  v-model="employeeDetail.join_service_company_time"
                  v-if="employeeDetail.join_service_company_time"
                ></el-input>
                <el-input readonly v-else>无</el-input>
              </el-form-item>
              <el-form-item label="入职公司时间">
                <el-input
                  readonly
                  v-model="employeeDetail.join_company_time"
                  v-if="employeeDetail.join_company_time"
                ></el-input>
                <el-input readonly v-else>无</el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="初始合同起期">
                <el-input
                  readonly
                  v-model="employeeDetail.contract_first_begin_time"
                ></el-input>
              </el-form-item>
              <el-form-item label="当期合同起期">
                <el-input
                  readonly
                  v-model="employeeDetail.contract_end_time"
                ></el-input>
              </el-form-item>
              <el-form-item label="当期合同终期">
                <el-input
                  readonly
                  v-model="employeeDetail.fund_time"
                ></el-input>
              </el-form-item>
              <el-form-item label="当期合同年限">
                <el-input
                  readonly
                  v-model="employeeDetail.contract_service_year"
                ></el-input>
              </el-form-item>
              <el-form-item label="试用期满时间">
                <el-input
                  readonly
                  v-model="employeeDetail.period_expire_time"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item>
          <el-row style="display: flex; justify-content: space-between">
            <el-col :span="11">
              <el-form-item
                label="任职时间"
                v-if="employeeDetail.period_expire_time"
              >
                <el-input
                  readonly
                  v-model="employeeDetail.period_expire_time"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="考核期满时间"
                v-if="employeeDetail.probation_expire_time"
              >
                <el-input
                  readonly
                  v-model="employeeDetail.probation_expire_time"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="任职经历"
                v-if="employeeDetail.office_job_exp"
              >
                <el-input
                  readonly
                  v-model="employeeDetail.office_job_exp"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item
                label="考核期限"
                v-if="employeeDetail.probation_period"
              >
                <el-input
                  readonly
                  v-model="employeeDetail.probation_period"
                ></el-input>
              </el-form-item>
              <el-form-item label="任职文号" v-if="employeeDetail.office_sn">
                <el-input
                  readonly
                  v-model="employeeDetail.office_sn"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form-item>
        <el-divider></el-divider>
        <el-form-item>
          <el-row style="display: flex; justify-content: space-between">
            <el-col :span="11">
              <el-form-item
                label="社保号"
                v-if="employeeDetail.social_security"
              >
                <el-input
                  readonly
                  v-model="employeeDetail.social_security"
                ></el-input>
              </el-form-item>
              <el-form-item label="投保时间" v-if="employeeDetail.social_time">
                <el-input
                  readonly
                  v-model="employeeDetail.social_time"
                ></el-input>
              </el-form-item>
              <el-form-item label="缴费基数" v-if="employeeDetail.social_base">
                <el-input
                  readonly
                  v-model="employeeDetail.social_base"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="个人账号"
                v-if="employeeDetail.accumulation_fund"
              >
                <el-input
                  readonly
                  v-model="employeeDetail.accumulation_fund"
                ></el-input>
              </el-form-item>
              <el-form-item label="意外险">
                <el-input
                  readonly
                  v-model="employeeDetail.accident_insurance"
                  v-if="employeeDetail.accident_insurance"
                ></el-input>
                <el-input readonly v-else>无</el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="投保地点">
                <el-input
                  readonly
                  v-model="employeeDetail.social_place"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="投保险数"
                v-if="employeeDetail.social_number"
              >
                <el-input
                  readonly
                  v-model="employeeDetail.social_number"
                ></el-input>
              </el-form-item>
              <el-form-item label="投保时间" v-if="employeeDetail.fund_time">
                <el-input
                  readonly
                  v-model="employeeDetail.fund_time"
                ></el-input>
              </el-form-item>
              <el-form-item label="购买地点">
                <el-input
                  readonly
                  v-model="employeeDetail.fund_place"
                ></el-input>
              </el-form-item>
              <el-form-item label="投保比例">
                <el-input
                  readonly
                  v-model="employeeDetail.insurance_rate"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form-item>
        <el-divider></el-divider>
        <el-row style="display: flex; justify-content: space-between">
          <el-col :span="11">
            <el-form-item
              v-if="employeeDetail.idcard_front_url"
              label="身份证正面"
            >
              <el-image
                :src="employeeDetail.idcard_front_url"
                fit="contain"
                :preview-src-list="[employeeDetail.idcard_front_url]"
                style="width: 100%; height: 200px"
              ></el-image>
            </el-form-item>
            <el-form-item
              v-if="employeeDetail.personal_img_url"
              label="个人证照"
            >
              <el-image
                :src="employeeDetail.personal_img_url"
                fit="contain"
                :preview-src-list="[employeeDetail.personal_img_url]"
                style="width: 100%; height: 200px"
              ></el-image>
            </el-form-item>
            <el-form-item
              v-if="employeeDetail.social_card_img_url"
              label="社保卡"
            >
              <el-image
                :src="employeeDetail.social_card_img_url"
                fit="contain"
                :preview-src-list="[employeeDetail.social_card_img_url]"
                style="width: 100%; height: 200px"
              ></el-image>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item
              v-if="employeeDetail.idcard_back_url"
              label="身份证反面"
            >
              <el-image
                :src="employeeDetail.idcard_back_url"
                fit="contain"
                :preview-src-list="[employeeDetail.idcard_back_url]"
                style="width: 100%; height: 200px"
              ></el-image>
            </el-form-item>
            <el-form-item v-if="employeeDetail.edu_prove_url" label="学历证明">
              <el-image
                :src="employeeDetail.edu_prove_url"
                fit="contain"
                :preview-src-list="[employeeDetail.edu_prove_url]"
                style="width: 100%; height: 200px"
              ></el-image>
            </el-form-item>
            <el-form-item
              v-if="employeeDetail.fund_card_img_url"
              label="公积金卡"
            >
              <el-image
                :src="employeeDetail.fund_card_img_url"
                fit="contain"
                :preview-src-list="[employeeDetail.fund_card_img_url]"
                style="width: 100%; height: 200px"
              ></el-image>
            </el-form-item>
            <el-form-item v-if="employeeDetail.quit_prove_url" label="离职证明">
              <el-image
                :src="employeeDetail.quit_prove_url"
                fit="contain"
                :preview-src-list="[employeeDetail.quit_prove_url]"
                style="width: 100%; height: 200px"
              ></el-image>
            </el-form-item>
            <el-form-item label="离职原因" v-if="employeeDetail.quit_reason">
              <el-input
                readonly
                v-model="employeeDetail.quit_reason"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider></el-divider>
        <el-form-item>
          <el-row style="display: flex; justify-content: space-between">
            <el-col :span="11">
              <el-form-item label="备注" v-if="employeeDetail.remark">
                <el-input readonly v-model="employeeDetail.remark"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="入职介绍人">
                <el-input
                  readonly
                  v-model="employeeDetail.introducer"
                  v-if="employeeDetail.introducer"
                ></el-input>
                <el-input readonly v-else>无</el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form-item>
        <el-divider></el-divider>
        <div>紧急联系人</div>
        <el-form-item>
          <el-row style="display: flex; justify-content: space-between">
            <el-col :span="11">
              <el-form-item label="姓名">
                <el-input
                  readonly
                  v-model="employeeDetail.emergency_name"
                ></el-input>
              </el-form-item>
              <el-form-item label="联系电话">
                <el-input
                  readonly
                  v-model="employeeDetail.emergency_relate"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <!-- <el-form-item label="住址">
                                <el-input
                                    readonly
                                    v-model="employeeDetail.emergency_address"
                                ></el-input>
                            </el-form-item> -->
              <el-form-item label="关系">
                <el-input
                  readonly
                  v-model="employeeDetail.emergency_phone"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <div class="custom_button no_select" @click="dialogVisible7 = false">
          确 定
        </div>
      </span>
    </el-dialog>

    <el-dialog title="批量签署" width="40%" :visible.sync="dialogFormVisible">
      <el-form>
        <el-form-item label="签署合同">
          <el-select
            style="witdh: 100%"
            v-model="contracts"
            filterable
            multiple
            reserve-keyword
            placeholder="请选择要签署的合同"
          >
            <el-option
              v-for="item in contract_options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="!item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="onBatchSignSubmit">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="分配权限" :visible.sync="dialogVisible8" width="30%">
      <el-form
        :model="form8"
        label-position="top"
        :rules="rulesEight"
        ref="formEight"
      >
        <el-form-item prop="account" label="员工账号" required>
          <!-- <div> 员工账号</div> -->
          <el-input
            v-model="form8.account"
            placeholder="请输入员工账号"
            style="width: 217px"
          ></el-input>
        </el-form-item>
        <el-form-item prop="role_id" required>
          <el-select v-model="form8.role_id">
            <el-option
              v-for="item in rolelist"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible8 = false">取 消</el-button>
        <el-button type="primary" @click="submitrole">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 搜索 -->
    <el-dialog
      :title="`搜索筛选${
        search.keyword.trim().length > 0
          ? '(当前输入框有输入,将会影响搜索的结果)'
          : ''
      }`"
      :visible.sync="showSearchDialog"
      width="70%"
      center
      :close-on-click-modal="false"
    >
      <el-form :inline="true" :model="searchForm" ref="searchForm">
        <el-row>
          <el-col :span="8">
            <el-form-item label="所在单位" class="normal_form">
              <el-select
                v-model="searchForm.department_name"
                placeholder="请选择所在单位/项目"
              >
                <el-option
                  v-for="item in department_options"
                  :key="item.id"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="所在项目" class="normal_form">
              <el-select
                v-model="searchForm.duty_name"
                placeholder="请选择所在单位/项目"
              >
                <el-option
                  v-for="item in projectList"
                  :key="item.id"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="用工类别" class="normal_form">
              <el-radio-group v-model="searchForm.is_social_security">
                <el-radio :label="1">A</el-radio>
                <el-radio :label="0">B</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="工种" class="normal_form">
              <el-input
                v-model="searchForm.work_type"
                placeholder="请输入工种"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="岗位层级" class="normal_form">
              <el-input v-model="searchForm.post_level"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="岗位名称" class="normal_form">
              <el-select
                v-model="searchForm.station_name"
                placeholder="请选择岗位名称"
              >
                <el-option
                  v-for="item in station_options"
                  :key="item.id"
                  :label="item.station_id"
                  :value="item.name"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="姓名" class="normal_form">
              <el-input
                v-model="searchForm.real_name"
                placeholder="请输入姓名"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="年龄最小值" class="normal_form">
              <el-input
                v-model="searchForm.age_min"
                placeholder="年龄最小值"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="年龄最大值" class="normal_form">
              <el-input
                v-model="searchForm.age_max"
                placeholder="年龄最大值"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="性别" class="normal_form">
              <el-radio-group v-model="searchForm.sex">
                <el-radio label="男">男</el-radio>
                <el-radio label="女">女</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8"
            ><el-form-item label="身份证有效止期" class="normal_form">
              <el-date-picker
                v-model="searchForm.certifity_expired_start"
                type="date"
                placeholder="请选择身份证有效止期"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker> </el-form-item
          ></el-col>
          <el-col :span="8">
            <el-form-item label="政治面貌" class="normal_form">
              <el-input
                v-model="searchForm.face"
                placeholder="请输入政治面貌"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="婚否" class="normal_form">
              <el-radio-group v-model="searchForm.is_marry">
                <el-radio :label="0">未婚</el-radio>
                <el-radio :label="1">已婚</el-radio>
                <el-radio :label="2">离异</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="文化程度" class="normal_form">
              <el-input
                v-model="searchForm.edu"
                placeholder="请输入文化程度"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="户口所在地省市区" class="normal_form">
              <el-cascader
                v-model="searchForm.dataModel"
                placeholder="请选择户口所在地省市区"
                :options="cityList"
              >
              </el-cascader>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="户口地址" class="normal_form">
              <el-input
                v-model="searchForm.address"
                placeholder="请选择户口地址"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="最早入职开始时间" class="normal_form">
              <el-date-picker
                v-model="searchForm.earliest_entry_start_time"
                type="date"
                placeholder="最早入职开始时间"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="最早入职结束时间" class="normal_form">
              <el-date-picker
                v-model="searchForm.earliest_entry_end_time"
                type="date"
                placeholder="最早入职结束时间"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="入职劳务公司开始时间" class="normal_form">
              <el-date-picker
                v-model="searchForm.join_service_company_start_time"
                type="date"
                placeholder="入职劳务公司开始时间"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="入职劳务公司结束时间" class="normal_form">
              <el-date-picker
                v-model="searchForm.join_service_company_end_time"
                type="date"
                placeholder="入职劳务公司结束时间"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="入职公司开始时间" class="normal_form">
              <el-date-picker
                v-model="searchForm.join_company_start_time"
                type="date"
                placeholder="入职公司开始时间"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="入职公司结束时间" class="normal_form">
              <el-date-picker
                v-model="searchForm.join_company_end_time"
                type="date"
                placeholder="入职公司结束时间"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="初始合同起期开始时间" class="normal_form">
              <el-date-picker
                v-model="searchForm.contract_first_begin_start_time"
                type="date"
                placeholder="初始合同起期开始时间"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="初始合同起期结束时间" class="normal_form">
              <el-date-picker
                v-model="searchForm.contract_first_begin_end_time"
                type="date"
                placeholder="初始合同起期结束时间"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="当期合同起期开始时间" class="normal_form">
              <el-date-picker
                v-model="searchForm.contract_begin_start_time"
                type="date"
                placeholder="当期合同起期开始时间"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="当期合同起期结束时间 " class="normal_form">
              <el-date-picker
                v-model="searchForm.contract_begin_end_time"
                type="date"
                placeholder="当期合同起期结束时间 "
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="当期合同终期开始时间" class="normal_form">
              <el-date-picker
                v-model="searchForm.contract_end_start_time"
                type="date"
                placeholder="当期合同终期开始时间"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="当期合同终期结束时间" class="normal_form">
              <el-date-picker
                v-model="searchForm.contract_end_end_time"
                type="date"
                placeholder="当期合同终期结束时间"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="试用期满开始时间" class="normal_form">
              <el-date-picker
                v-model="searchForm.period_expire_start_time"
                type="date"
                placeholder="试用期满开始时间"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="试用期满结束时间" class="normal_form">
              <el-date-picker
                v-model="searchForm.period_expire_end_time"
                type="date"
                placeholder="试用期满结束时间"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="正常工作时间工资最小值" class="normal_form">
              <el-input
                v-model="searchForm.normal_work_salary_min"
                placeholder="请选择正常工作时间工资最小值"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="正常工作时间工资最大值" class="normal_form">
              <el-input
                v-model="searchForm.normal_work_salary_max"
                placeholder="请选择正常工作时间工资最大值"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="月度薪酬标准最小值" class="normal_form">
              <el-input
                v-model="searchForm.salary_base_min"
                placeholder="请选择月度薪酬标准最小值"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="月度薪酬标准最大值" class="normal_form">
              <el-input
                v-model="searchForm.salary_base_max"
                placeholder="请选择月度薪酬标准最大值"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="开户行类别" class="normal_form">
              <el-input
                v-model="searchForm.bank_deposit"
                placeholder="请输入开户行类别"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="社保投保地点" class="normal_form">
              <el-input
                v-model="searchForm.social_place"
                placeholder="请输入社保投保地点"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="公积金购买地点" class="normal_form">
              <el-input
                v-model="searchForm.fund_place"
                placeholder="请输入公积金购买地点"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="发薪公司" class="normal_form">
              <el-input
                v-model="searchForm.payroll_company"
                placeholder="请输入发薪公司"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="公司名称" class="normal_form">
              <el-input
                v-model="searchForm.company_name"
                placeholder="请输入公司名称"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="加点替岗1工资标准最小值" class="normal_form">
              <el-input
                v-model="searchForm.add_replace_wages_standard_first_min"
                placeholder="请输入加点替岗1工资标准最小值"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="加点替岗1工资标准最大值" class="normal_form">
              <el-input
                v-model="searchForm.add_replace_wages_standard_first_max"
                placeholder="请输入加点替岗1工资标准最大值"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="加点替岗2工资标准最小值" class="normal_form">
              <el-input
                v-model="searchForm.add_replace_wages_standard_second_min"
                placeholder="请输入加点替岗2工资标准最小值"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="加点替岗2工资标准最大值" class="normal_form">
              <el-input
                v-model="searchForm.add_replace_wages_standard_second_max"
                placeholder="请输入加点替岗2工资标准最大值"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="加点替岗3工资标准最小值" class="normal_form">
              <el-input
                v-model="searchForm.add_replace_wages_standard_third_min"
                placeholder="请输入加点替岗3工资标准最小值"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="加点替岗3工资标准最大值" class="normal_form">
              <el-input
                v-model="searchForm.add_replace_wages_standard_third_max"
                placeholder="请输入加点替岗3工资标准最大值"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="加点替岗类别" class="normal_form">
              <el-radio-group
                v-model="searchForm.add_replace_wages_standard_type"
              >
                <el-radio :label="1">1</el-radio>
                <el-radio :label="2">2</el-radio>
                <el-radio :label="3">3</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="个税专项扣除额最小值" class="normal_form">
              <el-input
                v-model="searchForm.deduct_tax_amount_min"
                placeholder="请输入个税专项扣除额最小值"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="个税专项扣除额最大值" class="normal_form">
              <el-input
                v-model="searchForm.deduct_tax_amount_max"
                placeholder="请输入个税专项扣除额最大值"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showSearchDialog = false">取 消</el-button>
        <el-button type="primary" @click="toSearch(true)">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import City from "@/minix/getCity";
export default {
  data() {
    const checkArray = (rule, value, callback) => {
      if (value.length < 2) return callback(new Error("请选择员工子公司/部门"));
      callback();
    };

    return {
      search: {
        keyword: "",
      },
      tableData: [],
      statistics: {},
      // 分页参数
      page: {
        size: 15,
        page: 1,
        total: 0,
      },
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible4: false,
      dialogVisible5: false,
      dialogVisible6: false,
      dialogVisible7: false,
      dialogVisible8: false,
      dialogVisible9: false, // 借岗弹框
      addQrcode: "",
      department_options: [],
      station_options: [],
      employeeDetail: {},
      dialogFormVisible: false,
      contract_options: [],
      contracts: [],
      leave: true,
      form9: {
        real_name: "",
        department: [],
        department_id: "",
        duty_id: "",
        station_id: "",
        staff_id: "",
        borrow_salary_base: "",
      },
      form: {
        mobile: "",
        real_name: "",
        department: [],
        department_id: "",
        duty_id: "",
        station_id: "",
      },
      form3: {
        id: "",
        salary_base: "",
        normal_work_salary: "",
      },
      form4: {
        real_name: "",
        department: [],
        department_id: "",
        duty_id: "",
        station_id: "",
        new_salary_base: "",
        new_attendance_standard: "",
        new_normal_work_salary: "",
      },
      // 点击调岗时用户的详细数据
      formUserDetail: {},
      form5: {
        staff_ids: "",
        quit_time: "",
        quit_type: 1,
        reason: "个人原因",
        economic_compensation: "",
      },
      form6: {
        staff_id: "",
        quit_type: 3,
        nowork_time: "",
        begin_nowork_time: "",
        end_nowork_time: "",
        absenteeism: "",
        quit_remind_time: "",
      },
      form7: {
        staff_id: "",
        quit_type: 3,
        nowork_time: [],
        absenteeism: "",
      },
      form8: {
        choose_id: "",
        role_id: "",
        account: "",
      },
      rulesEight: {
        account: [
          { required: true, message: "请填写员工账号", trigger: "blur" },
          { required: true, message: "请填写员工账号", trigger: "change" },
        ],
        role_id: [
          { required: true, message: "请选择用户权限", trigger: "blur" },
        ],
      },
      rules: {
        mobile: [
          {
            required: true,
            message: "请填写员工手机号",
            trigger: "blur",
          },
        ],
        real_name: [
          {
            required: true,
            message: "请填写员工姓名",
            trigger: "blur",
          },
        ],
        department: [
          {
            required: true,
            validator: checkArray,
            trigger: "change",
          },
        ],
        station_id: [
          {
            required: true,
            message: "请选择员工岗位",
            trigger: "change",
          },
        ],
      },
      rules3: {
        salary_base: [
          {
            required: true,
            message: "请填写基本月薪",
            trigger: "blur",
          },
        ],
        normal_work_salary: [
          {
            required: true,
            message: "请填写正常工作时间工资",
            trigger: "blur",
          },
        ],
      },
      rules4: {
        station_id: [
          {
            required: true,
            message: "请选择员工岗位",
            trigger: "change",
          },
        ],
        department: [
          { required: true, message: "员工子公司/部门", trigger: "change" },
        ],
        new_salary_base: [
          { required: true, message: "请输入基本薪水", trigger: "change" },
        ],
        new_attendance_standard: [
          { required: true, message: "请输入出勤标准", trigger: "change" },
        ],
        new_normal_work_salary: [
          {
            required: true,
            message: "请输入正常工作时间工资",
            trigger: "change",
          },
        ],
        certifity_expired: [
          { required: true, message: "请选择生效时间", trigger: "change" },
        ],
      },
      rules5: {
        quit_time: [
          {
            required: true,
            message: "请选择离职时间",
            trigger: "change",
          },
        ],
        quit_type: [
          {
            required: true,
            message: "请选择离职类型",
            trigger: "change",
          },
        ],
      },
      rules6: {
        nowork_time: [
          {
            required: true,
            message: "请选择未上班日期",
            trigger: "change",
          },
        ],
        absenteeism: [
          {
            required: true,
            message: "请填写旷工天数",
            trigger: "blur",
          },
        ],
        quit_remind_time: [
          {
            required: true,
            message: "请填写二次催告时间",
            trigger: "change",
          },
        ],
      },
      rules9: {
        department: [
          {
            required: true,
            validator: checkArray,
            trigger: "change",
          },
        ],
        station_id: [
          {
            required: true,
            message: "请选择员工岗位",
            trigger: "change",
          },
        ],
      },
      batch: 1,
      options: [
        {
          value: 1,
          label: "离职",
        },
        {
          value: 2,
          label: "同步到打卡机",
        },
        // {
        //     value: 3,
        //     label: "签署合同/协议",
        // },
        // {
        //     value: 4,
        //     label: "查看合同/协议",
        // },
      ],

      tableHeaderStyle: {
        backgroundColor: "#F5F8FA",
        textAlign: "center",
      },
      tableCellStyle: {
        textAlign: "center",
      },
      rolelist: [],
      company_id: "",
      multipleSelection: [], //多选数组
      searchForm: {
        department_name: "", //id
        duty_name: "", //id
        is_social_security: 1,
        work_type: "",
        post_level: "",
        station_name: "", //id
        real_name: "",
        age_min: "",
        age_max: "",
        sex: "男",
        certifity_expired_start: "",
        face: "",
        is_marry: 0,
        edu: "",
        dataModel: [],
        address: "",
        earliest_entry_start_time: "",
        earliest_entry_end_time: "",
        join_service_company_start_time: "",
        join_service_company_end_time: "",
        join_company_start_time: "",
        join_company_end_time: "",
        contract_first_begin_start_time: "",
        contract_first_begin_end_time: "",
        contract_end_start_time: "",
        contract_end_end_time: "",
        period_expire_start_time: "",
        period_expire_end_time: "",
        normal_work_salary_min: "",
        normal_work_salary_max: "",
        salary_base_min: "",
        salary_base_max: "",
        bank_deposit: "",
        social_place: "",
        fund_place: "",
        payroll_company: "",
        company_name: "",
        add_replace_wages_standard_first_min: "",
        add_replace_wages_standard_first_max: "",
        add_replace_wages_standard_second_min: "",
        add_replace_wages_standard_second_max: "",
        add_replace_wages_standard_third_min: "",
        add_replace_wages_standard_third_max: "",
        deduct_tax_amount_min: "",
        deduct_tax_amount_max: "",
        add_replace_wages_standard_type: 1,
      }, //搜索弹窗数据
      projectList: [], //项目列表
      cityList: [], //城市列表
      showSearchDialog: false, // 搜索
    };
  },
  inject: ["reload"],
  created() {
    this.loadData();
    this.load_rolelist();

    this.getEmployeeStatistics()
      .then((res) => {
        console.log(res);
        this.statistics = res.data.data;
      })
      .catch((e) => {
        console.log(e);
      });

    this.getDepartmentList()
      .then((res) => {
        console.log("getDepartmentList", res);
        this.department_options = res.data.data.list.data.map((item) => {
          return {
            value: item.id,
            label: item.name,
            children: item.child.map((item) => {
              return {
                value: item.id,
                label: item.name,
              };
            }),
          };
        });
      })
      .catch((e) => {
        console.error(e);
      });

    this.getStationList()
      .then((res) => {
        console.log(res);
        this.station_options = res.data.data.list;
      })
      .catch((e) => {
        console.log(e);
      });

    // 城市列表
    this.cityList = City;

    /**
     * 获取合同
     */
    this.getStandardContractList({
      page: 1,
      size: 10000,
    })
      .then((res) => {
        console.log(res);
        this.contract_options = res.data.data.list.map((item) => {
          return {
            label: item.title,
            value: item.template_id,
          };
        });
      })
      .catch((e) => {
        this.$message.error(e.msg);
      });
  },
  methods: {
    ...mapActions(["uploadFile"]),
    ...mapActions("contract", ["postBatchSign", "getStandardContractList"]),
    ...mapActions("enterprise", ["get_rolelist"]),
    ...mapActions("employee", [
      "getEmployeeList",
      "getDepartmentList",
      "getStationList",
      "getEmployeeStatistics",
      "postCreateEmployee",
      "getEmployeeTemplate",
      "postImportEmployee",
      "setEmployeeAdmin",
      "getEmployeeQrcode",
      "postChangeSalary",
      "postPostTransfer",
      "borrowPostTransfer",
      "postEmployeeQuit",
      "getSearchEmployee",

      "sendInterpellate",
      "diffDay",
      "fire",
      "quitDetail",
      "cancelInterpellate",
      "getProjectList",

      "getEmployeeRosterDetail",
      "getSyncEmployeePunchMachine",
      "getSyncEmployeePunchMachineBatch",
      "getSyncPunchMachineLog",
      "exportRosters",
    ]),
    /**
     * 选择excel
     */
    chooseExcel() {
      this.$refs.fileInput.click();
    },
    //导出花名册
    exportRoster() {
      let idList = this.multipleSelection.reduce((prev, next) => {
        console.log(prev, next.id);
        next.id && prev.push(next.id);
        return prev;
      }, []);
      console.log("导出花名册", idList);
      if (idList.length == 0) {
        this.$message({
          showClose: true,
          message: "请选择员工",
          type: "warning",
        });
        return;
      }

      // let loading = this.$loading();
      let loading = this.$loading();

      this.exportRosters({ id: idList.join(","), name: "员工信息表" })
        .then((res) => {
          console.log(res);
          this.$alert(res.data.message, "导出花名册成功", {
            confirmButtonText: "确定",
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          loading.close();
        });
    },
    filterDepart(list, status) {
      console.log("certifity_expired", this.form.certifity_expired);
      console.log(list, "list");
      list.length > 0 &&
        list.map((item) => {
          item.value = item.id;
          item.label = item.name;
          item.child &&
            item.child.length > 0 &&
            item.child.map((e) => {
              e.value = e.id;
              e.label = e.name;
            });
          if (item.child && item.child.length > 0) {
            item.children = item.child;
          }
        });
      if (status == 2) {
        this.projectList = list;
      }
    },
    //表格多选
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 重置搜索
    resetFormSearch() {
      this.page.page = 1;
      this.loadData();
    },

    loadData(params = {}) {
      let loading = this.$loading();
      let { page, size } = this.page;
      let searchParams = { ...params, page, size };
      this.getEmployeeList(searchParams)
        .then((res) => {
          console.log("获取表格", res);
          res.data.data.list.map((item, index) => {
            item.index = index;
          });
          this.tableData = res.data.data.list;

          this.page.total = res.data.data.total;
          this.showSearchDialog = false;
          loading.close();
        })
        .catch((e) => {
          loading.close();
          this.$message.error(e);
        });
      // 单例初始化
      // if (!this.loadDataObj)
      //   this.loadDataObj = new LoadDataClass(this.getEmployeeList);

      // // 通知，用于修改对象属性
      // if (func) {
      //   func(this.loadDataObj);
      //   console.log("func", func, this.loadDataObj);
      // }

      // let loading = this.$loading();
      // this.loadDataObj
      //   .load(this.page.page)
      //   .then((res) => {
      //     console.log("staff", res);
      //     res.data.data.list.map((item, index) => {
      //       item.index = index;
      //     });
      //     this.tableData = res.data.data.list;

      //     this.page.total = res.data.data.total;
      //     loading.close();
      //   })
      //   .catch((e) => {
      //     loading.close();
      //     this.$message.error(e);
      //   });
    },

    tableRowClassName({ row }) {
      // console.log("row", row);
      if (row.is_red == 1) {
        return "warm-row";
      }
      return "";
    },

    /**
     * 搜索
     */
    toSearch(tag) {
      if (tag) {
        // department_options
        let params = { ...this.searchForm };
        // 过滤单位名称
        let departmentInfo = this.department_options.find(
          (item) => item.value == params.department_name
        );
        if (departmentInfo) {
          params.department_name = departmentInfo.label;
        }

        // 过滤项目名称
        let dutyInfo = this.projectList.find(
          (item) => item.value == params.duty_name
        );
        if (dutyInfo) {
          params.duty_name = dutyInfo.label;
        }

        //过滤岗位名称
        let stateInfo = this.projectList.find(
          (item) => item.id == params.station_name
        );
        if (stateInfo) {
          params.station_name = dutyInfo.label;
        }
        //过滤省市区id
        let dataModel = params.dataModel;
        delete params.dataModel;
        if (dataModel.length > 0) {
          params.province = dataModel[0];
          params.city = dataModel[1];
          params.area = dataModel[2];
        } else {
          params.province = "";
          params.city = "";
          params.area = "";
        }

        this.page.page = 1;

        this.loadData(params);
      } else {
        //项目列表
        this.getProjectList().then((res) => {
          // console.log("所在单位", res);
          if (res.data.code == 200) {
            this.filterDepart(res.data.data.list.data, "2");
          }
        });
        this.showSearchDialog = true;

        //
      }
    },

    /**
     * 同步用户到打卡机
     */
    syncUserPunchMachine(row) {
      console.log(row);
      let loading = this.$loading();
      this.getSyncEmployeePunchMachine({
        id: row.id,
        user_name: row.real_name,
      })
        .then((res) => {
          console.log(res);
          loading.close();
          this.$message.success(res.data.msg);
        })
        .catch((e) => {
          console.error(e);
          loading.close();
          this.$message.error(e.msg);
        });
    },

    /**
     * 同步打卡机信息
     */
    syncPunchMachine() {
      let loading = this.$loading();
      this.getSyncPunchMachineLog()
        .then((res) => {
          console.log(res);
          loading.close();
          this.$message.success(res.data.msg);
        })
        .catch((e) => {
          console.error(e);
          loading.close();
          this.$message.error(e.msg);
        });
    },

    /**
     * 查看员工档案
     */
    onCellClick(row, column, cell, event) {
      console.log(row, column, cell, event, cell.cellIndex);

      // 过滤第几单元格，主要是操作不触发
      if (cell.cellIndex == 12) {
        console.log(cell.cellIndex, cell, "索引");
        return;
      }
      let loading = this.$loading();
      this.getEmployeeRosterDetail({
        staff_id: row.id,
      })
        .then((res) => {
          console.log(res);
          if (res.data.data.detail.is_veteran != 0) {
            res.data.data.detail.is_veteran = true;
          }
          this.employeeDetail = res.data.data.detail;
          if (!this.employeeDetail) {
            this.$message.error("该用户信息为空");
          } else {
            // 数据处理
            this.employeeDetail.is_marry = this.employeeDetail.is_marry == 1;
            this.employeeDetail.is_birth = this.employeeDetail.is_birth == 1;
            this.employeeDetail.is_major_diseases =
              this.employeeDetail.is_major_diseases == 1;
            this.employeeDetail.is_disability =
              this.employeeDetail.is_disability == 1;
            this.employeeDetail.is_occupation_diseases =
              this.employeeDetail.is_occupation_diseases == 1;
            this.employeeDetail.is_harmful =
              this.employeeDetail.is_harmful == 1;
            let household_registration = "";
            if (this.employeeDetail.province)
              household_registration += this.employeeDetail.province;
            if (this.employeeDetail.city)
              household_registration += this.employeeDetail.city;
            if (this.employeeDetail.area)
              household_registration += this.employeeDetail.area;
            this.employeeDetail.household_registration = household_registration;
            if (this.employeeDetail.edu_exp?.length > 0)
              this.employeeDetail.edu_exp = this.employeeDetail.edu_exp
                .map((item) => {
                  return `${item.school} ${item.degree} ${item.major}\n${item.entry_date}-${item.leave_date}`;
                })
                .join("\n");
            if (this.employeeDetail.job_exp?.length > 0)
              this.employeeDetail.job_exp = this.employeeDetail.job_exp
                .map((item) => {
                  return `${item.company} ${item.department} ${item.position} ${item.industry}\n${item.entry_date}-${item.leave_date}\n${item.work_desc}`;
                })
                .join("\n");

            // 打开弹窗
            // this.dialogVisible7 = true;
            this.$router.push({
              name: "EmployeeDetail",
              params: { id: row.id, isSave: false },
            });
          }
          loading.close();
        })
        .catch((e) => {
          console.error(e);
          loading.close();
          this.$message.error(e.msg);
        });
    },

    /**
     * 添加员工二维码
     */
    onAddEmployeeQrcodeTap() {
      this.dialogVisible2 = true;
      this.company_id = JSON.parse(localStorage.getItem("user")).company_id;
      // let loading = this.$loading();
      // this.getEmployeeQrcode()
      //     .then((res) => {
      //         console.log(res);
      //         this.addQrcode = res.data.data.qrcode_url;
      //         this.dialogVisible2 = true;
      //         this.$nextTick(() => {
      //             loading.close();
      //         });
      //     })
      //     .catch((e) => {
      //         console.error(e);
      //         this.$message.error("获取二维码失败");
      //         this.$nextTick(() => {
      //             loading.close();
      //         });
      //     });
    },

    /**
     * 下载导入模板
     */
    downloadTamplate() {
      let loading = this.$loading();
      let name = "staff";
      this.getEmployeeTemplate(name)
        .then((res) => {
          console.log(res, "导入成功");
          loading.close();
          let filename =
            res.headers["content-disposition"].match(/filename=(.*)/)[1];
          filename = filename.replace(/^(\s|")+|(\s|")+$/g, "");
          // 将二进制流转为blob
          const blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          if (typeof window.navigator.msSaveBlob !== "undefined") {
            // 兼容IE，window.navigator.msSaveBlob：以本地方式保存文件
            window.navigator.msSaveBlob(blob, decodeURI(filename));
          } else {
            // 创建新的URL并指向File对象或者Blob对象的地址
            const blobURL = window.URL.createObjectURL(blob);
            // 创建a标签，用于跳转至下载链接
            const tempLink = document.createElement("a");
            tempLink.style.display = "none";
            tempLink.href = blobURL;
            tempLink.setAttribute("download", decodeURI(filename));
            // 兼容：某些浏览器不支持HTML5的download属性
            if (typeof tempLink.download === "undefined") {
              tempLink.setAttribute("target", "_blank");
            }
            // 挂载a标签
            document.body.appendChild(tempLink);
            tempLink.click();
            document.body.removeChild(tempLink);
            // 释放blob URL地址
            window.URL.revokeObjectURL(blobURL);
          }
        })
        .catch((e) => {
          console.log("下载失败", e);
          loading.close();
          console.error(e);
          this.$message.error(e.msg);
        });
    },

    /**
     * 导入花名册
     */
    async importRoster(file) {
      console.log(file, "file");
      console.log(this.$store.getters);
      let loading = this.$loading();
      try {
        let link = (
          await this.uploadFile({
            file: file.target.files[0],
            type: "staff_xls",
          })
        ).data.data.link;
        let res = await this.postImportEmployee(link);
        loading.close();
        console.log(res);
        // this.$message.success(res.data.msg);
        let error_remark = res.data.data.error_remark;
        if (error_remark.length > 0) {
          this.$confirm(error_remark.join(), "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "error",
          });
        } else {
          this.$alert(res.data.msg, "成功", {
            confirmButtonText: "确定",
            type: "success",
          });
        }

        this.loadData();
      } catch (e) {
        loading.close();
        console.error(e);
        // this.$message.error(e.msg);
        this.$alert(e.msg, "成功", {
          confirmButtonText: "确定",
          type: "success",
        });
      }
    },

    /**
     * 设置为管理员，分配权限
     */
    setAdmin(id, account) {
      console.log("account=>", account);
      this.dialogVisible8 = true;
      this.form8.choose_id = id;
      this.form8.account = account;
    },

    /**
     * 获取权限列表
     */
    load_rolelist() {
      let params = {};
      this.get_rolelist(params)
        .then((res) => {
          console.log("get_rolelist", res);
          this.rolelist = res.data.data.list;
        })
        .catch((e) => {
          this.$message.error(e.msg);
        });
    },

    /**
     * 提交权限
     */
    submitrole() {
      console.log(this.form8);
      this.$refs.formEight.validate((valid) => {
        if (valid) {
          this.$confirm("是否分配权限?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            let loading = this.$loading();
            this.setEmployeeAdmin({
              id: this.form8.choose_id,
              role_id: this.form8.role_id,
              account: this.form8.account,
            })
              .then((res) => {
                console.log(res);
                loading.close();
                this.$message.success(res.data.msg);
                this.loadData();
                this.dialogVisible8 = false;
              })
              .catch((e) => {
                console.log(e);
                loading.close();
                this.$message.error(e.msg);
                this.dialogVisible8 = false;
              });
          });
        }
      });
    },

    /**
     * 添加员工
     */
    onAddEmployee() {
      console.log(this.form);
      this.$refs.form.validate((valid) => {
        if (valid) {
          let loading = this.$loading();
          this.dialogVisible = false;
          this.form.department_id = this.form.department[0];
          this.form.duty_id = this.form.department[1];

          this.postCreateEmployee(this.form)
            .then((res) => {
              console.log(res);
              loading.close();
              this.$message.success(res.data.msg);
              this.loadData();
            })
            .catch((e) => {
              console.log(e);
              loading.close();
              this.$message.error(e.msg);
            });

          this.form = {
            mobile: "",
            real_name: "",
            department: [],
            department_id: "",
            duty_id: "",
            station_id: "",
          };
        } else {
          this.$notify.error({
            title: "提交失败",
            message: "请先完整填写表单",
          });
          return false;
        }
      });
    },

    // 调薪
    changeSalary(employee) {
      this.$confirm(
        "请先下发调岗调薪协议给企业员工签署后，再进行调岗、调薪",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "info",
        }
      ).then(() => {
        console.log(employee);
        this.selectedIds = employee.map((item) => item.id);
        this.selectedFddIds = employee.map((item) => item.fddUserInfo?.id);
        this.cantFddEmployee = employee.filter((item) => !item.fddUserInfo?.id);
        // 现在只有单个调薪
        this.form3 = {
          id: employee[0].id,
          salary_base: employee[0].salary_base,
          normal_work_salary: employee[0].normal_work_salary,
          real_name: employee[0].real_name,
        };
        this.dialogVisible3 = true;
      });
    },

    onChangeSalary() {
      this.$refs.form3.validate((valid) => {
        if (valid) {
          let loading = this.$loading();
          this.dialogVisible3 = false;
          this.postChangeSalary(this.form3)
            .then((res) => {
              console.log(res);
              loading.close();
              this.$message.success(res.data.msg);
              this.loadData();
            })
            .catch((e) => {
              console.log(e);
              loading.close();
              this.$message.error(e.msg);
            });
        } else {
          this.$notify.error({
            title: "提交失败",
            message: "请先完整填写表单",
          });
          return false;
        }
      });
    },

    // 调岗
    postTransfer(employee) {
      this.$confirm(
        "请先下发调岗调薪协议给企业员工签署后，在进行调岗、调薪",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "info",
        }
      ).then(() => {
        console.log(employee, "点击调岗");
        this.formUserDetail = employee[0];
        this.selectedIds = employee.map((item) => item.id);
        this.selectedFddIds = employee.map((item) => item.fddUserInfo?.id);
        this.cantFddEmployee = employee.filter((item) => !item.fddUserInfo?.id);
        // 现在只有单个调岗
        this.form4 = {
          id: employee[0].id,
          real_name: employee[0].real_name,
          department: employee[0].duty_id
            ? [employee[0].department_id, employee[0].duty_id]
            : [employee[0].department_id],
          department_id: employee[0].department_id,
          duty_id: employee[0].duty_id,
          station_id: employee[0].station_id,
          new_salary_base: employee[0].salary_base,
          new_attendance_standard: employee[0].attendance_standard,
          new_normal_work_salary: employee[0].normal_work_salary,
        };
        this.dialogVisible4 = true;
      });
    },
    // 借岗申请弹框
    showTransfer(datas) {
      console.log("data测试", datas);
      this.form9.borrow_salary_base = datas[0].salary_base;
      this.form9.real_name = datas[0].real_name;
      this.form9.staff_id = datas[0].id;
      this.form9.department_id = datas[0].department_id;
      this.form9.duty_id = datas[0].duty_id;
      this.dialogVisible9 = true;
    },

    borrowPostTransfers() {
      this.$refs.form9.validate((valid) => {
        if (valid) {
          let { staff_id, department, station_id, borrow_salary_base } =
            this.form9;
          let datakey = {
            staff_id,
            borrow_department_id: department[0] ?? "",
            borrow_duty_id: department[1] ?? "",
            borrow_station_id: station_id,
            borrow_salary_base,
          };
          // datakey["staff_id"] = this.form9.staff_id;
          // datakey["borrow_department_id"] = this.form9.department_id;
          // datakey["borrow_duty_id"] = this.form9.duty_id;
          // datakey["borrow_station_id"] = this.form9.station_id;
          // datakey["borrow_salary_base"] = this.form9.borrow_salary_base;

          this.borrowPostTransfer(datakey)
            .then((res) => {
              console.log("借岗", res);
              this.$message.success({
                message: res.data.msg ?? res.data.message,
              });
              this.$router.push("/employee/employeeBorrow");
            })
            .catch((e) => {
              console.log(e);
              this.$message.error(e.msg);
            });
        } else {
          this.$notify.error({
            title: "提交失败",
            message: "请先完整填写表单",
          });
          return false;
        }
      });
    },

    onPostTransfer() {
      this.$refs.form4.validate((valid) => {
        if (valid) {
          let loading = this.$loading();
          // this.dialogVisible4 = false;
          this.form4.department_id = this.form4.department[0];
          if (this.form4.department[1]) {
            this.form4.duty_id = this.form4.department[1];
          }
          // let { id, department_id, duty_id, station_id } = this.form4;
          let {
            id,
            department_id,
            duty_id,
            station_id,
            salary_base,
            attendance_standard,
            normal_work_salary,
          } = this.formUserDetail;
          let params = {
            // 原本的信息
            staff_id: id,
            origin_department_id: department_id,
            origin_duty_id: duty_id,
            origin_station_id: station_id,
            origin_salary_base: salary_base,
            origin_attendance_standard: attendance_standard,
            origin_normal_work_salary: normal_work_salary,

            // 新修改的信息
            // new_department_id: this.form4.new_department_id,
            new_department_id: this.form4.department_id,
            new_duty_id: this.form4.duty_id,
            new_station_id: this.form4.station_id,

            new_salary_base: this.form4.new_salary_base,
            new_attendance_standard: this.form4.new_attendance_standard,
            new_normal_work_salary: this.form4.new_normal_work_salary,
            transfer_date: this.form4.certifity_expired,
          };
          // console.log(JSON.parse(JSON.stringify(this.form4)));
          console.log(params);
          this.postPostTransfer(params)
            .then((res) => {
              this.dialogVisible4 = false;
              console.log(res);
              loading.close();
              this.$message.success(res.data.msg);
              this.loadData();
            })
            .catch((e) => {
              console.log(e);
              loading.close();
              this.$message.error(e.msg);
            });
        } else {
          this.$notify.error({
            title: "提交失败",
            message: "请先完整填写表单",
          });
          return false;
        }
      });
    },

    /**
     * 批量处理
     */
    toBatchProcessing() {
      let selectedItems = this.$refs.table.store.states.selection;
      console.log(selectedItems);
      if (!(selectedItems.length > 0)) {
        this.$message.warning("请至少选择一项进行操作");
        return;
      }
      switch (this.batch) {
        case 1: // 批量离职
          this.leavePost(selectedItems.map((item) => item.id));
          break;

        case 2: // 批量同步打卡机
          this.onBatchSyncPunchWay(selectedItems);
          break;

        default:
          break;
      }
    },

    // 离职
    leavePost(id) {
      (this.form5 = {
        staff_ids: "",
        quit_time: "",
        quit_type: 1,
        reason: "个人原因",
        economic_compensation: "",
      }),
        (this.form6 = {
          staff_id: "",
          quit_type: 3,
          nowork_time: "",
          begin_nowork_time: "",
          end_nowork_time: "",
          absenteeism: "",
          quit_remind_time: "",
        }),
        (this.form5.staff_ids = [id]);
      this.form6.staff_id = id;
      this.dialogVisible5 = true;
    },

    // 自离
    leavePost2(id) {
      this.form7.staff_id = id;
      this.dialogVisible6 = true;
      this.quitDetail({ staff_id: id })
        .then((res) => {
          let nowork_time = [];
          nowork_time[0] = res.data.data.detail.absenteeism_data.begin;
          nowork_time[1] = res.data.data.detail.absenteeism_data.end;
          this.$set(this.form7, "nowork_time", nowork_time);
          this.form7.absenteeism = res.data.data.detail.base_absenteeism;
        })
        .catch((e) => {
          console.log(e);
          this.$message.error(e.msg);
        });
    },

    changeQuitType(val) {
      this.form5.quit_type = val;
      this.form6.quit_type = val;
      console.log(val, "val");
      if (val == 3) {
        this.leave = false;
      } else {
        this.leave = true;
      }
    },

    getNoworkTime(val) {
      this.form6.begin_nowork_time = val[0];
      this.form6.end_nowork_time = val[1];
      this.diffDay({ begin_date: val[0], end_date: val[1] })
        .then((res) => {
          this.form6.absenteeism = res.data.data.day;
        })
        .catch((e) => {
          this.$message.error(e.msg);
        });
    },

    onLeavePost() {
      this.$refs.form5.validate((valid) => {
        if (valid) {
          let loading = this.$loading();
          this.dialogVisible5 = false;
          this.form5.staff_ids = this.form5.staff_ids.join(",");

          this.postEmployeeQuit(this.form5)
            .then((res) => {
              console.log(res);
              loading.close();
              this.$message.success(res.data.msg);
              this.loadData();
            })
            .catch((e) => {
              console.log(e);
              loading.close();
              this.$message.error(e.msg);
            });
        } else {
          this.$notify.error({
            title: "提交失败",
            message: "请先完整填写表单",
          });
          return false;
        }
      });
    },

    toSign() {
      if (this.cantFddEmployee.length > 0) {
        this.$alert(
          `员工 ${this.cantFddEmployee
            .map((item) => item.real_name)
            .join("、")} 未注册法大大`,
          "无法签署",
          {
            confirmButtonText: "确定",
          }
        );
        return;
      }
      this.dialogFormVisible = true;
    },

    toReminder() {
      this.$refs.form6.validate((valid) => {
        if (valid) {
          this.$confirm(
            "请将《催告上班通知书》发送EMS快递给员工，并在快递单上注明文件名称，快递单请妥善保管。",
            "提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }
          )
            .then(() => {
              let loading = this.$loading();
              this.dialogVisible5 = false;

              this.sendInterpellate(this.form6)
                .then((res) => {
                  console.log(res);
                  loading.close();
                  this.$message.success(res.data.msg);
                  this.loadData();
                  window.open(res.data.data.link, "_blank");
                })
                .catch((e) => {
                  console.log(e);
                  loading.close();
                  this.$message.error(e.msg);
                });
            })
            .catch(() => {});
        }
      });
    },

    toRelieveContract() {
      this.$confirm(
        "请将《解除劳动合同通知书》发送EMS快递给员工，并在快递单上注明文件名称，快递单请妥善保管。",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          let loading = this.$loading();
          this.dialogVisible6 = false;

          this.fire({ staff_id: this.form6.staff_id })
            .then((res) => {
              console.log(res);
              loading.close();
              this.$message.success(res.data.msg);
              this.loadData();
              window.open(res.data.data.link, "_blank");
            })
            .catch((e) => {
              console.log(e);
              loading.close();
              this.$message.error(e.msg);
            });
        })
        .catch(() => {});
    },

    toRelieveReminder() {
      this.$confirm("确定要解除催告?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let loading = this.$loading();
          this.dialogVisible6 = false;

          this.cancelInterpellate({ staff_id: this.form6.staff_id })
            .then((res) => {
              console.log(res);
              loading.close();
              this.$message.success(res.data.msg);
              this.loadData();
            })
            .catch((e) => {
              console.log(e);
              loading.close();
              this.$message.error(e.msg);
            });
        })
        .catch(() => {});
    },

    onBatchSignSubmit() {
      if (this.contracts.length == 0) {
        this.$notify.error({
          title: "提交失败",
          message: "请先选择合同",
        });
        return;
      }
      this.dialogFormVisible = false;
      let loading = this.$loading();

      this.postBatchSign({
        template_ids: this.contracts.join(","),
        party_b_fdd_userids: this.selectedFddIds.join(","),
      })
        .then((res) => {
          console.log(res);
          loading.close();
          this.$message.success(res.data.msg);
        })
        .catch((e) => {
          console.log(e);
          loading.close();
          this.$message.error(e.msg);
        });
    },

    onBatchSyncPunchWay(rows) {
      let loading = this.$loading();
      this.getSyncEmployeePunchMachineBatch({
        ids: rows.map((item) => item.id).join(","),
        user_names: rows.map((item) => item.real_name).join(","),
      })
        .then((res) => {
          console.log(res);
          loading.close();
          this.$message.success(res.data.msg);
        })
        .catch((e) => {
          console.log(e);
          loading.close();
          this.$message.error(e.msg);
        });
    },
  },
};

// class LoadDataClass {
//   constructor(request, nav_type = null, keyword = null) {
//     this.nav_type = nav_type;
//     this.keyword = keyword;
//     this.func = request;
//   }

//   load(page) {
//     let params = {
//       page,
//     };
//     if (this.keyword) params.keywords = this.keyword;
//     if (this.nav_type) params.nav_type = this.nav_type;
//     console.log("load", params);
//     return this.func(params);
//   }
// }
</script>
<style lang="scss" scoped>
.page {
  position: relative;
  padding-bottom: 50px;
  min-height: calc(100% - 50px);
}

.title {
  font-size: 14px;
  font-weight: bold;
  color: #1c2438;
}

.filter_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0;

  .filter_search {
    display: flex;
    align-items: center;

    .filter_search_input {
      width: 350px;
      height: 36px;
      margin-right: 10px;

      ::v-deep .el-input__inner {
        height: 36px;
      }
    }

    > div:not(:first-child) {
      margin-left: 20px;
    }
  }

  .filter_btns {
    display: flex;
    align-items: center;

    > div:not(:first-child) {
      margin-left: 20px;
    }
  }
}

.counts_box {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;

  .counts_item {
    width: 790-40px;
    height: 135-30px;
    background-size: 100% 100% !important;
    padding: 15px 20px;
    display: flex;
    align-items: center;

    &:first-child {
      background: url("~@/assets/bgs/red_bar_bg.png") no-repeat;
    }

    &:last-child {
      background: url("~@/assets/bgs/blue_bar_bg.png") no-repeat;
    }

    > div {
      flex: 1;
      color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 46px;
      cursor: pointer;

      &:not(:last-child) {
        border-right: 1px solid rgba($color: #ffffff, $alpha: 0.2);
      }

      font-size: 14px;

      > div:last-child {
        margin-top: 10px;

        span {
          font-size: 20px;
        }
      }
    }
  }
}

.operations {
  // text-align: right;

  > div {
    font-size: 14px;
    color: #406bff;
    cursor: pointer;

    // &:not(:last-child) {
    //     margin-right: 13px;
    // }
  }
}

.bottom_betch_operation {
  position: absolute;
  bottom: 0;
  display: flex;

  .betch_select {
    width: 130px;
    height: 36px;
    margin-right: 10px;

    ::v-deep .el-input__inner {
      height: 36px;
    }

    ::v-deep .el-input__icon {
      line-height: 36px;
    }
  }
}

.tableList_explain {
  margin: 20px 0;
}
</style>
